<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:class="['xs', 'sm'].includes(windowWidth) ? 'p-2' : 'p-3'"
		class="animated fadeIn">
		<div class="row">
			<div class="col-12 col-md-7 col-lg-8 mb-3">
				<b-card
					no-body
					class="h-100">
					<template v-slot:header>
						<h4 class="mb-0">
							{{ translate('points_transfer_considerations') }}
						</h4>
					</template>
					<b-card-body class="pb-0">
						<div class="text-center mb-4">
							<img
								:src="require(`@/assets/images/themes/${themeName}/wallets/transfer.png`)"
								align="center">
						</div>
						<b-card-title class="h6">
							{{ translate('points_transfers_title') }}
						</b-card-title>
						<b-card-text
							class="mb-0"
							v-html="translate('points_transfers_text')" />
					</b-card-body>
				</b-card>
			</div>
			<div
				:class="{ 'mb-3': !['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md">
				<b-card
					no-body
					class="h-100">
					<template v-slot:header>
						<h4 class="mb-0">
							{{ translate('points_transfer') }}
						</h4>
					</template>
					<b-card-body class="pb-0">
						<b-card-text>
							{{ translate('transfer_form_text') }}
						</b-card-text>
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? 'py-2' : 'p-2'"
							class="row">
							<div class="col-6 pl-4">
								{{ translate('balance_available') }}
							</div>
							<div class="col-6 font-weight-bold text-right">
								{{ balance }}
							</div>
						</div>
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? 'py-2' : 'p-2'"
							class="row">
							<form
								class="form-group col-12 mb-0"
								@change="clearError($event.target.name)"
								@keydown="clearError($event.target.name)">
								<div
									id="userIdInput"
									class="form-group row"
									:errors="errors['user_id']">
									<div class="col-6">
										<label
											for="amount"
											class="label pl-2">
											{{ translate('transfer_to') }}
											<span class="text-danger">* </span>
											<span
												v-b-tooltip.hover.html
												:title="translate('transfer_to_tooltip')">
												<i class="fas fa-info-circle" />
											</span>
										</label>
									</div>
									<div
										:class="hasError('username') ? 'mb-3' : ''"
										class="col-6">
										<b-input
											v-model="username"
											:placeholder="translate('transfer_to_placeholder')" />
										<template v-if="hasError('username')">
											<p
												v-for="error in errors['username']"
												:key="error"
												class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
												v-text="error" />
										</template>
									</div>
								</div>
								<div
									id="amountInput"
									class="form-group row"
									:errors="errors['amount']">
									<div class="col-6">
										<label
											for="amount"
											class="label pl-2">
											{{ translate('amount') }}
											<span class="text-danger">*</span>
										</label>
									</div>
									<div class="col-6">
										<input
											id="amount"
											v-model="amount"
											name="amount"
											type="number"
											:class="hasError('amount') ? 'is-invalid' : ''"
											class="form-control form-control-sm text-right"
											aria-describedby="amount"
											placeholder="0">
									</div>
									<div class="col-12">
										<template v-if="hasError('amount')">
											<p
												v-for="error in errors['amount']"
												:key="error"
												class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
												v-text="error" />
										</template>
									</div>
									<div class="col-12 text-right">
										<span
											v-b-tooltip.hover.leftbottom
											class="text-muted"
											:title="showBaseBalance ? amountToBase : ''"
											:class="showBaseBalance ? 'pointer btn-link' : ''">
											{{ amountToGlobal }}
										</span>
									</div>
								</div>
							</form>
						</div>
						<div class="row p-3 d-flex">
							<b-button
								:disabled="loading || !valid"
								:class="(loading || !valid) ? '' : 'media-btn'"
								type="submit"
								variant="primary"
								size="md"
								block
								class="btn bg-primary-alt"
								@click="verifyTransfer">
								<i
									v-if="loading"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('points_transfer') }}
							</b-button>
						</div>
					</b-card-body>
				</b-card>
			</div>
			<b-modal
				id="wallet-password-modal"
				:title="translate('wallet_password')"
				ok-only>
				{{ translate('wallet_password_modal', { companyName }) }}
			</b-modal>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { DEFAULT_COUNTRY_CURRENCY } from '@/settings/Country';
import { RESTRICTED_CURRENCY } from '@/settings/Wallets';
import { Wallets as WalletMessages } from '@/translations';
import Alert from '@/util/Alert';
import Wallets from '@/util/Wallets';

export default {
	name: 'WalletPointsTransfer',
	messages: [WalletMessages],
	mixins: [WindowSizes],
	data() {
		return {
			summary: new Wallets(),
			transfer: new Wallets(),
			alert: new Alert(),
			amount: '',
			password: '',
			companyName: process.env.VUE_APP_TITLE,
			themeName: process.env.VUE_APP_THEME,
			username: '',
			defaultCurrency: DEFAULT_COUNTRY_CURRENCY,
			transferType: 'restricted',
			restrictedCurrency: RESTRICTED_CURRENCY,
		};
	},
	computed: {
		balanceData() {
			try {
				return this.summary.data.response.data.response.balances.default;
			} catch (error) {
				return [];
			}
		},
		balance() {
			try {
				return this.balanceData.balance;
			} catch (error) {
				return [];
			}
		},
		showBaseBalance() {
			try {
				return this.balanceData.show_base_balance;
			} catch (error) {
				return [];
			}
		},
		baseBalance() {
			try {
				return this.balanceData.base_balance;
			} catch (error) {
				return [];
			}
		},
		amountToGlobal() {
			try {
				const fullAmount = this.amount * this.balanceData.rate;
				const amount = fullAmount.toFixed(this.balanceData.decimals);
				return this.translate(this.balanceData.currency_code.toLowerCase(), { amount });
			} catch (error) {
				return this.translate(this.balanceData.currency_code.toLowerCase(), { amount: 0 });
			}
		},
		amountToBase() {
			try {
				const fullAmount = this.amount * this.baseBalance.rate * this.balanceData.rate;
				const amount = fullAmount.toFixed(this.baseBalance.decimals);
				return this.translate(this.baseBalance.currency_code.toLowerCase(), { amount });
			} catch (error) {
				return this.translate(this.baseBalance.currency_code.toLowerCase(), { amount: 0 });
			}
		},
		errors() {
			return this.transfer.errors.errors;
		},
		loading() {
			return !!this.transfer.data.loading;
		},
		valid() {
			return !!this.username.length && !!this.amount.length;
		},
	},
	created() {
		this.summary.getPointsBalance();
	},
	methods: {
		hasError(field) {
			if (typeof this.transfer.errors.errors[field] !== 'undefined') {
				return true;
			}
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.transfer.errors.errors[field];
				this.transfer.errors.errors = { ...this.errors };
			}
		},
		verifyTransfer() {
			const trans = {
				title: this.translate('points_transfer'),
				text: this.translate('points_transfer_text', { amount: this.amount, money: this.amountToGlobal, user: this.username }),
			};
			const options = {
				confirmButtonText: this.translate('confirm'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
				const payload = {
					amount: this.amount,
					username: this.username,
				};
				this.transfer.createPointsTransfer(payload).then(() => {
					this.alert.toast('success', this.translate('transfer_success'));
					this.amount = '';
					this.username = '';
					this.transfer.errors.errors = {};
				}).catch(() => {}).finally(() => {
					this.summary.getPointsBalance();
					this.$parent.$parent.$parent.pointsSummary.getPointsBalance();
				});
			}).catch(() => {});
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			const hideWalletTransfer = vm.$user.details().hide_wallet_transfer;

			if (hideWalletTransfer) {
				vm.$router.replace({ name: 'Home' });
			}
		});
	},
};
</script>
